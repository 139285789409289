import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { SchedulingDiscount, SchedulingTime } from '../../queries/order/types';
import { useCartStore } from '../../stores/cart';
import {
  calculateSchedulingDisplayValue,
  DeliveryType,
  formatDate,
  formatHour,
} from '@onbeefapp/constants';
import React from 'react';
import { useOrderDetails } from '../../stores/order-details';

interface CardSchedulingProps {
  time: SchedulingTime;
  selected: boolean;
  date: string;
  baseDiscount?: SchedulingDiscount;
  width?: string;
  onUnselect?: () => void;
}

const CardScheduling: React.FC<CardSchedulingProps> = ({
  selected,
  time,
  date,
  baseDiscount,
  onUnselect,
  width = '46%',
}) => {
  const { deliveryType } = useOrderDetails();
  const setDeliveryTime = useCartStore((state) => state.setDeliveryTime);
  const {
    frete,
    isFreteGratis,
    isACombinar,
    freteValue,
    withFreteGratisDiscount,
  } = React.useContext(OrderDetailsContext);

  const showTimeRange = (time: SchedulingTime) => {
    let start = formatHour(time.start);
    let end = formatHour(time.end || '');
    if (start === end) {
      end = time.end || '';
    }
    return end ? `${start} - ${end}hrs` : `${start}hrs`;
  };

  const discount = !time.default ? time.discount || baseDiscount : null;
  const isWithdraw = deliveryType === DeliveryType.WITHDRAWAL;

  return (
    <div
      onClick={() => {
        if (selected || time.default) {
          setDeliveryTime(undefined);
          onUnselect && onUnselect();
        } else {
          setDeliveryTime(
            date,
            { ...time, discount: discount || null },
            freteValue,
          );
        }
      }}
      className={`flex flex-col justify-between space-y-1 p-2 border-2 border-[#d4d4d4] rounded-lg flex-shrink-0 cursor-pointer ${
        selected && '!border-[#0c8a47]'
      }`}
      style={{ width }}
    >
      <div className="flex flex-row w-full space-x-2">
        <p className="font-bold text-sm">
          {time.default ? 'Padrão' : 'Agendada'}
        </p>
        {!isACombinar && discount && (
          <p className="uppercase bg-[#fad2d2] text-[#700000] p-1 py-[2px] rounded-xl font-bold text-sm ml-1">
            - {discount.percent}%
          </p>
        )}
      </div>
      <p className="font-medium text-xs whitespace-nowrap">
        {formatDate(date)}, {showTimeRange(time)}
      </p>
      <div className="flex w-full">
        {!isWithdraw && (
          <p
            className={`font-medium text-sm ${
              frete === 'Frete Grátis' ||
              (frete === 'À Combinar' &&
                'bg-[#d2fadf] px-1 !text-sm rounded-md font-semibold text-[#007023]')
            }`}
          >
            {calculateSchedulingDisplayValue({
              discount,
              frete,
              freteValue,
              isACombinar,
              isFreteGratis,
              withFreteGratisDiscount,
            })}
          </p>
        )}
        {selected && (
          <div className="flex ml-2 flex-shrink justify-end text-[#0c8a47]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardScheduling;
