export const useSlug = () => {
  const testDomains = [
    'localhost',
    'vercel.app',
    'netlify.app',
    'now.sh',
    'ngrok',
  ];
  const url = window.location.href;
  // if the URL includes any of the possible test domains, use the test slug
  if (testDomains.some((domain) => url.includes(domain))) {
    return {
      slug: import.meta.env.VITE_REACT_CUSTOM_SLUG,
    };
  }

  if (url.includes('lojista') || url.includes('extensao')) {
    const slug = localStorage.getItem('merchant_slug');
    return {
      slug: slug,
    };
  }

  return {
    slug: window.location.href.split('//')[1]?.split('.')[0],
  };
};
