import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';

const OrderEntrypoint: React.FC = () => {
  const params = useSlug();
  const location = useLocation();

  const { data: merchantData } = useMerchantCache(params.slug);

  const setMerchant = useMerchantStore((state) => state.setMerchant);

  if (merchantData?.content) {
    setMerchant(merchantData?.content);
  }

  // Scroll to top on route change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default OrderEntrypoint;
