import api from '../../services/api';

export async function getWhatsappMessage(
  order_id: string,
  order_code: string,
  token?: string,
  with_checkout_link = false,
) {
  const { data } = await api.get<{ message: string }>(
    `/all/customers/orders/${order_id}/whatsapp/message/${order_code}?payment_link=${with_checkout_link}`,
    token
      ? {
          headers: {
            'X-Access-Token': token,
          },
        }
      : {},
  );
  return data.message;
}
