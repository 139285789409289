import React from 'react';
import { useUserStore } from '../../stores/user';
import { useMerchantStore } from '../../stores/merchant';
import Swal from 'sweetalert2';

const ConfirmOrderWithoutClubModal: React.FC = () => {
  const name = useUserStore((state) => state.name);

  const clubs = useMerchantStore((state) => state.clubs);

  const handleConfirm = () => {
    Swal.clickConfirm();
  };

  const handleCancel = () => {
    Swal.clickCancel();
  };

  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <div className={`flex rounded-lg`}>
        <p className="text-xl font-semibold">Desbloqueie suas Vantagens!</p>
      </div>
      <div className={`flex my-2 text-sm font-medium`}>
        <p>
          Olá, {name}! Você é assinante do {clubs?.[0]?.name}, mas está prestes
          a finalizar sua compra sem estar logado.
        </p>
      </div>
      <div className={`flex my-2 text-sm font-medium`}>
        Deseja continuar o pedido?
      </div>

      <div className="flex flex-col w-full space-y-1 pt-2">
        <button
          className="px-4 py-2 w-full bg-primary text-contrastText text-sm font-semibold rounded-lg"
          onClick={handleConfirm}
        >
          Entrar para aproveitar
        </button>
        <button
          className="px-4 py-2 w-full text-sm font-semibold text-white bg-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
          onClick={handleCancel}
        >
          Continuar sem benefícios
        </button>
      </div>
    </div>
  );
};

export default ConfirmOrderWithoutClubModal;
