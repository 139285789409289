import {
  PlusIcon,
  CreditCardIcon,
  LockClosedIcon,
} from '@heroicons/react/24/solid';
import { TextInput } from '@onbeef/components/input';
import { cards as cardsImages, maskOnlyNumbers } from '@onbeefapp/constants';
import cx from 'classnames';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useOrderDetails } from '../../../stores/order-details';
import { useUserStore } from '../../../stores/user';
import { useFormContext } from 'react-hook-form';

export const CheckoutCreditCard: React.FC = () => {
  const navigate = useNavigate();

  const methods = useFormContext();

  const { cards, localCards } = useUserStore((state) => ({
    cards: state.cards,
    localCards: state.local_cards,
  }));

  const handleNewCreditCard = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      navigate('/card/new');
    }
  };

  return (
    <div className="w-full flex flex-col mb-2">
      <div
        className={cx(
          'w-full flex flex-col gap-3',
          cards.length > 0 || localCards.length > 0 ? 'mb-4' : '',
        )}
      >
        {cards.map((card) => {
          return (
            <CardItem id={card.id} brand={card.brand} number={card.number} />
          );
        })}
        {localCards.map((card) => {
          return (
            <CardItem
              id={card.id}
              brand={card.formatted.brand}
              number={card.formatted.number}
            />
          );
        })}
      </div>
      <button
        type="button"
        className="w-full text-primary flex items-center justify-center gap-2 font-semibold border border-dashed border-current rounded-lg py-1"
        onClick={handleNewCreditCard}
      >
        <PlusIcon className="w-5 h-5 text-primary stroke-2" />
        Adicionar
      </button>
    </div>
  );
};

const CardItem: React.FC<{ id: string; brand: string; number: string }> = ({
  id,
  brand,
  number,
}) => {
  const { selectedCardID, setSelectedCardID } = useOrderDetails((state) => ({
    selectedCardID: state.selectedCardID,
    setSelectedCardID: state.setSelectedCardID,
  }));

  const selected = id === selectedCardID;

  return (
    <button
      type="button"
      className={cx(
        'w-full flex justify-between items-center rounded-lg bg-white p-2 shadow-border',
        selected ? 'shadow-green-500' : '',
      )}
      onClick={() => {
        setSelectedCardID(id);
      }}
    >
      <span className="font-semibold text-gray-600">{number}</span>
      <img src={cardsImages[brand]} alt={brand} className="h-7" />
    </button>
  );
};

export const ConfirmCvv: React.FC = () => {
  const methods = useForm();

  const onSubmit = () => {
    Swal.clickConfirm();
  };

  React.useEffect(() => {
    const input = document.getElementById('cvv') as HTMLInputElement;
    if (input) {
      input.focus();
      input.click();
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="w-full p-6 bg-white rounded-lg flex flex-col gap-4 items-center"
      >
        <div className="flex items-center gap-2">
          <LockClosedIcon className="h-6 w-6 text-primary" />
          <span className="text-lg font-semibold text-gray-700">
            Confirme seu CVV
          </span>
        </div>

        <TextInput
          icon={<CreditCardIcon className="ml-3 text-primary h-6 w-6" />}
          name="cvv"
          id="cvv"
          inputMode="numeric"
          autoComplete="cvv"
          maxLength={4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            methods.setValue('card.cvv', maskOnlyNumbers(e.target.value));
            methods.trigger('card.cvv');
          }}
          className="w-full pl-12 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-current focus:outline-none shadow-sm"
          placeholder="Digite o CVV"
        />

        <button className="w-full rounded-lg bg-primary text-white font-semibold p-3 hover:bg-primary-dark transition">
          Confirmar
        </button>
      </form>
    </FormProvider>
  );
};
