import { Modal } from '@onbeef/components/modal';
import { diff, isDateExpired } from '@onbeefapp/constants';
import React from 'react';

interface ExpiredSubscriptionModalProps {
  merchantPhone?: string;
  expirationDate?: string;
}

const getDaysSinceExpiration = (expirationDate: string): number => {
  return diff(new Date(), new Date(expirationDate)).days;
};

export const ExpiredSubscriptionModal: React.FC<
  ExpiredSubscriptionModalProps
> = ({ merchantPhone, expirationDate }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isTemporary, setIsTemporary] = React.useState(false);

  React.useEffect(() => {
    if (expirationDate && isDateExpired(expirationDate)) {
      const daysSinceExpiration = getDaysSinceExpiration(expirationDate);

      if (daysSinceExpiration <= 10) {
        setIsOpen(false);
      } else if (daysSinceExpiration <= 40) {
        setIsOpen(true);
        setIsTemporary(true);
      } else {
        setIsOpen(true);
        setIsTemporary(false);
      }
    }
  }, [expirationDate]);

  const handleWhatsAppClick = () => {
    if (!merchantPhone) return;
    const phoneNumber = merchantPhone.replace(/[^0-9]/g, '');
    const url = `https://api.whatsapp.com/send?phone=+55${phoneNumber}&text=Olá, fui comprar pelo site da Onbeef, mas vi que não está mais disponível. Podem me ajudar?`;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      closeIcon={false}
      onClose={() => {}}
      className="w-4/5 lg:w-1/2"
    >
      <div className="p-8 flex flex-col items-center text-center gap-4">
        <span className="text-4xl">{isTemporary ? '😥' : '😭'}</span>
        <span className="text-xl font-semibold">
          {isTemporary
            ? 'Temporariamente Indisponível'
            : 'Praticidade interrompida'}
        </span>
        <span>
          {isTemporary
            ? 'Nosso site está temporariamente fora do ar para compras online.'
            : 'Infelizmente não estamos mais oferecendo a facilidade e a praticidade de comprar online por nosso site.'}
        </span>
        <span>
          {isTemporary
            ? 'Pedimos desculpas por qualquer transtorno e estamos trabalhando para resolver o mais rápido possível.'
            : 'Agradecemos sua preferência e pedimos desculpas pela inconveniência.'}
        </span>
        <div className="flex flex-col items-center gap-2">
          <span className="font-medium text-primary">Precisa de ajuda?</span>
          <button
            type="button"
            className="p-2 rounded-lg bg-primary text-contrastText"
            onClick={handleWhatsAppClick}
          >
            {isTemporary
              ? 'Entre em contato conosco pelo WhatsApp'
              : 'Entre em contato no whatsapp'}
          </button>
        </div>
      </div>
    </Modal>
  );
};
