import React from 'react';
import HeaderBar from '../../components/Headers/HeaderBar';
import ListOrders from '../../components/Lists/ListOrders';
import { useListOrders } from '../../queries/my-orders';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useMyOrdersStore } from '../../stores/my-orders';
import { useNavigate } from 'react-router-dom';

const MyOrders: React.FC = () => {
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { orders, setOrders, setSelectedOrder, setIsLoading } =
    useMyOrdersStore();

  const { mutate: fetchOrders, data: ordersRes } = useListOrders();

  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const [dataLength, setDataLength] = React.useState(0);

  const nextPage = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  React.useEffect(() => {
    if (!merchant?.id) return;

    setIsLoading(true);
    fetchOrders({ page, merchant_id: merchant.id });
  }, [page]);

  React.useEffect(() => {
    if (ordersRes) {
      setOrders(page, ordersRes.data);
      setHasMore(!!ordersRes.next_page_url);
      setDataLength((prev) => prev + ordersRes.per_page);
      setIsLoading(false);
    }
  }, [ordersRes]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { goBack: location.pathname } });
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    setSelectedOrder(undefined);
  }, []);

  return (
    <div className={'flex flex-col p-6 space-y-4'}>
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Meus pedidos
        </span>
      </HeaderBar>

      <span className="text-sm font-medium">
        Veja todos pedidos realizados e clique para ver detalhes.
      </span>

      <ListOrders
        dataLength={dataLength}
        hasMore={hasMore}
        nextPage={nextPage}
        orders={Object.values(orders).reduce((acc, cur) => acc.concat(cur), [])}
      />
    </div>
  );
};

export default MyOrders;
