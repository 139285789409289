import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { TextArea } from '@onbeef/components/input';
import { Stars } from '@onbeef/components/stars';
import { StatusType, Toast } from '@onbeefapp/constants';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useContinueActionAfterSales } from '../hooks/useContinueActionAfterSales';
import { useRegisterAssessment } from '../queries/my-orders';
import { CreateOrderResponse } from '../queries/order/types';
import { useMerchantStore } from '../stores/merchant';
import { useGeneratedOrderStore } from '../stores/pix';
import { statusPage } from '../utils/function';
import LoadingSpin from './LoadingSpin';

interface RatingForm {
  text: string;
  stars: number;
}

const StatusPage: React.FC = () => {
  const params = useParams();
  const order_id = params.id ?? '';
  const { merchant, payment_methods } = useMerchantStore((state) => ({
    merchant: state.merchant,
    payment_methods: state.payment_methods,
  }));

  const { mutate, data, isSuccess, isLoading } = useRegisterAssessment();

  const { generatedOrder } = useGeneratedOrderStore((state) => ({
    generatedOrder: state.generatedOrder,
  }));

  const { continueAction, isLoyaltyRefreshLoading } =
    useContinueActionAfterSales(order_id);

  const handleContinueAction = () => {
    continueAction();
  };

  const pix = merchant?.payment_accounts
    ? merchant?.payment_accounts?.find(
        (account) => account?.type?.toLowerCase().includes('pix'),
      )
    : null;
  const pixPaymentMethod = payment_methods
    ? payment_methods.find((method) =>
        method.name.toLowerCase().includes('pix'),
      )
    : null;

  const order = statusPage(
    (generatedOrder[order_id] ?? undefined) as CreateOrderResponse | undefined,
  );

  const methods = useForm<RatingForm>({
    defaultValues: {
      text: '',
      stars: 0,
    },
  });

  const submitRate = ({ stars, text }: RatingForm) => {
    if (!merchant?.id) return;

    mutate({
      type: 'APP',
      merchant_id: merchant.id,
      order_id: order_id,
      rating: stars,
      comments: text,
      motives: [],
    });
  };

  React.useEffect(() => {
    if (data && isSuccess) {
      handleContinueAction();
    }
  }, [data]);

  const onSubmit = (data: RatingForm) => {
    const { stars, text } = data;
    if (stars !== 0 || text !== '') {
      return submitRate(data);
    }

    handleContinueAction();
  };

  const continueText = () => {
    if (
      merchant?.whatsapp_instance &&
      merchant.whatsapp_instance.is_connected &&
      merchant.whatsapp_instance.config_details.send_orders_to_customers
    ) {
      return 'Quero acompanhar meu pedido';
    }

    if (merchant?.orders_on_whatsapp) {
      return 'Confirmar no WhatsApp';
    }

    return 'Acompanhar meu pedido';
  };

  return (
    <div
      className={`flex items-center bg-white p-5 text-center w-full h-screen`}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full flex flex-col p-2 gap-4 mb-12"
        >
          <div className="flex flex-col w-full items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              {order.status === StatusType.SUCCESS && (
                <CheckCircleIcon className="w-20 h-20 text-green-500" />
              )}
              {order.status === StatusType.ERROR && (
                <XCircleIcon className="w-20 h-20 text-red-500" />
              )}
              <h1 className="my-3 text-2xl font-bold text-center text-gray-700">
                {order.title}
              </h1>
              <p className="text-gray-500 text-sm font-semibold">
                {order?.message}
              </p>
            </div>
            {order.status === StatusType.SUCCESS && (
              <div className="flex flex-col w-full justify-center items-center gap-3">
                <span className="font-base text-xl text-black">
                  Como avaliaria sua experiência até agora?
                </span>
                <Stars
                  value={methods.watch('stars')}
                  handler={(v) => methods.setValue('stars', v)}
                />
                <TextArea
                  name="text"
                  className="mt-2 w-full h-[100px] text-base resize-none outline-none rounded-md px-2 py-1 border border-gray-300"
                  placeholder="Escreva aqui se viu algo que podemos melhorar"
                />
                <span className="text-sm">
                  Por favor, considere avaliar, essa pesquisa é 100% anônima e
                  sua opinião é muito importante
                </span>
              </div>
            )}
            <button
              type="submit"
              className="w-full rounded-lg bg-primary p-3 text-contrastText mt-6 flex justify-center"
            >
              {isLoading || isLoyaltyRefreshLoading ? (
                <LoadingSpin />
              ) : (
                continueText()
              )}
            </button>
            {generatedOrder[order_id]?.label_method_payment === 'PIX' &&
              pixPaymentMethod?.pivot?.status === 1 &&
              pixPaymentMethod?.pivot?.visible === 1 && (
                <>
                  <CopyToClipboard
                    text={pix?.key ?? ''}
                    onCopy={() => {
                      Toast.success('Pix copiado!');
                    }}
                  >
                    <button
                      type="button"
                      className="w-full rounded-lg border border-current text-primary p-2 mt-2 flex items-center justify-center gap-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                        />
                      </svg>
                      Copiar chave PIX
                    </button>
                  </CopyToClipboard>
                </>
              )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default StatusPage;
