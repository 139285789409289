import { Complement as OComplement } from '../../../../apps/catalogo/src/queries/order/types'
import { WppComplement } from './Whatsapp/Interfaces'
import { ProductType } from './constants'
import { ItemOrderDetails } from './interfaces'

export const removeAccents = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function convertToSlug(productName: string): string {
  const accentsMap: { [key: string]: string } = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    à: 'a',
    è: 'e',
    ì: 'i',
    ò: 'o',
    ù: 'u',
    ã: 'a',
    õ: 'o',
    â: 'a',
    ê: 'e',
    î: 'i',
    ô: 'o',
    û: 'u',
    ä: 'a',
    ë: 'e',
    ï: 'i',
    ö: 'o',
    ü: 'u',
    ç: 'c',
    ñ: 'n',
    ß: 'ss',
  }

  const slug = productName
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Remove consecutive hyphens
    .replace(/[^\x00-\x7F]/g, (char) => accentsMap[char] || '') // Remove accents
    .replace(/[()]/g, '') // Replace parentheses with hyphens
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .trim() // Trim leading/trailing whitespace

  return slug
}

export const calculateTotalCutsPrice = (item: ItemOrderDetails) => {
  return item.cuts
    .map(
      (cut) =>
        (cut.pivot.price_per_gram || item.price_per_gram) *
        (cut.pivot.quantity || 0)
    )
    .reduce((acc, cur) => acc + cur, 0)
}

export const calculateOrderItemTotalAndQuantityV1 = (
  item: ItemOrderDetails,
  changed_at: null | string = null
) => {
  let quantity: number | null = Number(item.quantity),
    grams: number | null = -1,
    item_type = 'g' // can be: g for grams, x for units
  // Caso exista o min_grams e o item não tenha sido alterado, o valor de grams será o min_grams
  const itemGrams: number | null =
    item.min_grams && changed_at === null ? item.min_grams : item.grams
  const price: number = item.price * quantity
  const discount = item.club_id ? 0 : item.discount
  let total = price - discount

  if (item?.product?.estimated_weight && !itemGrams) {
    //old: const estimatedWeight = quantity * item.product.estimated_weight
    quantity = quantity * item.product.estimated_weight
    item_type = 'g'
  } else if (itemGrams) {
    quantity = itemGrams
    total = item.price_per_gram * itemGrams
    item_type = 'g'
  } else {
    grams = null
    quantity = Number(item.quantity)
    item_type = 'x'
  }

  // Caso o item tenha sido alterado, o valor de total será o total_price_gram
  if (changed_at != null) {
    total = item.total_price_gram ? item.total_price_gram : item.price
  }

  if (quantity === 0 && grams === null && total == 0) {
    for (let i = 0; i < item.complements.length; i++) {
      const complement = item.complements[i]
      if (!complement) continue
      if (complement.pivot.price! > 0) {
        // Caso o complemento tenha sido alterado, o valor de price será o price_in_item
        if (changed_at == null) {
          total += complement.pivot.price!
        } else {
          total += complement.pivot.price_in_item
        }
      }
    }
    quantity = 1
  }

  return {
    grams,
    quantity,
    total,
    item_type,
  }
}

export const formatGrams = (grams: string) => {
  const n = Number(grams)
  if (isNaN(n)) return ''
  if (n >= 1000) {
    const kg = n / 1000
    return `${kg.toFixed(2).replace(/\.?0+$/, '')} kg`
  } else {
    return `${grams} g`
  }
}

export const calculateOrderKitItemTotalAndQuantity = (
  item: ItemOrderDetails
) => {
  const isKg =
    item.product.product_type === ProductType.KG ||
    item.product.price_per_kilo != null
  return {
    quantity: isKg ? formatGrams(String(item.grams)) : `${item.quantity}x`,
    total: isKg ? item.total_price_gram : item.total_price_quantity,
  }
}

export type OrderComplement = OComplement & WppComplement
export const calculateOrderComplementTotalAndQuantityV1 = (
  item: ItemOrderDetails,
  complement: WppComplement,
  changed_at: null | string = null
) => {
  let price: number = Math.max(
    0,
    complement.pivot.price ?? complement.pivot.price_in_item
  )

  // Caso exista o min_grams e o item não tenha sido alterado, o valor de grams será o min_grams
  const complementGrams: number | null =
    complement.pivot?.min_grams && changed_at === null
      ? complement.pivot?.min_grams
      : complement.pivot?.grams
  let quantity: number | null = Number(complement.pivot.quantity)
  let item_type: 'g' | 'x' = 'x'

  if (complement.pivot.price >= 0 && !complementGrams) {
    item_type = 'x'
  } else if (item.product.estimated_weight !== null && !complementGrams) {
    if (item.product.estimated_weight! * complement.pivot.quantity !== 0) {
      quantity = item.product.estimated_weight! * quantity
      item_type = 'g'
    }
  } else if (complementGrams) {
    quantity = complementGrams
    item_type = 'g'
  }

  // Caso o item tenha sido alterado, o valor de price será o price_in_item
  if (changed_at != null) {
    price = complement.pivot.price_in_item
  } else if (
    complement.pivot.min_price_in_item != null &&
    complement.pivot.min_price_in_item > 0
  ) {
    price = complement.pivot.min_price_in_item
  }

  return {
    quantity,
    price,
    item_type,
  }
}

export function formatCountDown(seconds: number) {
  const numMinutes = Math.floor((seconds % 3600) / 60)
  const numSeconds = seconds % 60
  const formattedMinutes = String(numMinutes).padStart(2, '0')
  const formattedSeconds = String(numSeconds).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

// function to compare the countdown with the quote expired time
export const calculateRemainingTime = (expiredAt: number) => {
  const timeNow = new Date().getTime()
  if (timeNow > expiredAt) {
    return 0
  } else {
    return Math.max(0, Math.floor((expiredAt - timeNow) / 1000))
  }
}

export const formatPackageQuantityComments = (
  comments?: string | null,
  package_quantity?: number | null
) => {
  return `${
    package_quantity && package_quantity > 1
      ? `Separar em ${package_quantity} porções. `
      : ''
  }${comments || ''}`
}
