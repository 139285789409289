import { StatusType } from '@onbeefapp/constants';
import { useNavigate } from 'react-router-dom';
import { useRefreshLoyaltyData } from '../queries/loyalty';
import { CreateOrderResponse } from '../queries/order/types';
import { useAuthStore } from '../stores/login';
import { useMerchantStore } from '../stores/merchant';
import { useGeneratedOrderStore } from '../stores/pix';
import { statusPage } from '../utils/function';
import { getWhatsappURL } from '../utils/Whatsapp/WhatsappURL';

export const useContinueActionAfterSales = (orderID: string) => {
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const { generatedOrder, usedLoyalty } = useGeneratedOrderStore((state) => ({
    generatedOrder: state.generatedOrder,
    usedLoyalty: state.usedLoyalty,
  }));
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);

  const {
    mutateAsync: refreshLoyaltyData,
    isLoading: isLoyaltyRefreshLoading,
  } = useRefreshLoyaltyData();

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    data && updateLoyalty(data);
  };

  const order = statusPage(
    (generatedOrder[orderID] ?? undefined) as CreateOrderResponse | undefined,
  );

  const continueAction = async () => {
    if (generatedOrder[orderID] && order.status === StatusType.SUCCESS) {
      const id = generatedOrder[orderID]?.id ?? '';

      let wppURL = await getWhatsappURL(
        merchant!.phone,
        orderID,
        generatedOrder[orderID]?.code ?? '',
        generatedOrder[orderID]?.token,
      );

      if (merchant?.whatsapp_instance) {
        if (
          merchant.whatsapp_instance.is_connected &&
          merchant.whatsapp_instance.config_details.send_orders_to_customers &&
          merchant.whatsapp_instance.phone
        ) {
          const phone = merchant.whatsapp_instance.phone;
          wppURL =
            'https://api.whatsapp.com/send?phone=+' +
            encodeURIComponent(parseInt(phone.replace(/[^0-9]/g, ''))) +
            '&text=' +
            encodeURIComponent('Quero acompanhar meu pedido!');
        }
      }

      if (usedLoyalty) {
        await handleRefreshLoyalty();
      }
      if (merchant?.orders_on_whatsapp) {
        window.open(wppURL, '_blank');
        navigate(`/`, { replace: true });
      } else {
        navigate(`/orders/${id}`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  };

  return {
    continueAction,
    isLoyaltyRefreshLoading,
  };
};
