import Tooltip from '@onbeef/components/tooltip';
import {
  DeliveryType,
  HelpIcon,
  PaymentMethodType,
  currencyFormat,
  formatCurrency,
} from '@onbeefapp/constants';
import * as React from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import { OrderDetailsContext } from '../../../contexts/OrderDetailsContext';
import { useSlug } from '../../../hooks/useSlug';
import { useMerchantCache } from '../../../queries/home';
import { useCartStore } from '../../../stores/cart';
import { useMerchantStore } from '../../../stores/merchant';
import { useOrderDetails } from '../../../stores/order-details';
import { getMarginTax, showCouponDiscount } from '../../../utils/CartUtils';

export const CheckoutSummary: React.FC = () => {
  const { coupon, crmBonusDiscount } = useCartStore((state) => ({
    coupon: state.coupon,
    crmBonusDiscount: state.crmBonusDiscount,
  }));

  const { deliveryType } = useOrderDetails((state) => {
    return {
      deliveryType: state.deliveryType,
    };
  });

  const { slug } = useSlug();

  const { isLoading: isLoadingMerchant } = useMerchantCache(slug);
  const { loyalty_programme } = useMerchantStore((state) => ({
    loyalty_programme: state.loyalty_programme,
  }));

  const {
    frete,
    withFreteGratisDiscount,
    subtotalFormatted,
    valueFeesFormatted,
    totalWithFees,
    withFees,
    subtotal,
    subtotalWithDiscount,
    hasProductPerKilo,
    discountFormatted,
    hasClubDiscount,
    userClubDiscount,
    userClubDiscountFormatted,
    loyaltyRewardFormatted,
    loyaltyGiftFormatted,
    marginValue,
  } = React.useContext(OrderDetailsContext);

  const { selectedPaymentMethod } = useOrderDetails((state) => ({
    selectedPaymentMethod: state.selectedPaymentMethod,
  }));

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full space-y-1 border-b-[1px] pb-2 mt-2 rounded-lg py-2">
        <Skeleton isLoading={isLoadingMerchant} className="w-32 h-4 rounded-lg">
          <div className="text-base font-semibold text-left mb-2">
            Resumo de valores
          </div>
        </Skeleton>

        <div className="flex flex-row justify-between text-sm font-normal text-left w-full">
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-16 h-4 rounded-lg"
          >
            <div>Subtotal</div>
          </Skeleton>
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-12 h-4 rounded-lg"
          >
            {subtotalFormatted}
          </Skeleton>
        </div>
        {deliveryType === DeliveryType.DELIVERY && (
          <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Taxa de entrega</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {withFreteGratisDiscount ? (
                  <>
                    <s>{frete}</s>{' '}
                    <span className="text-green-600">Grátis</span>
                  </>
                ) : (
                  frete
                )}
              </div>
            </Skeleton>
          </div>
        )}
        {crmBonusDiscount > 0 && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Cashback</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{formatCurrency(crmBonusDiscount)}</div>
            </Skeleton>
          </div>
        )}
        {subtotal !== subtotalWithDiscount && coupon && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Desconto (cupom)</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {coupon
                  ? showCouponDiscount(coupon, subtotal - userClubDiscount)
                  : discountFormatted}
              </div>
            </Skeleton>
          </div>
        )}
        {hasClubDiscount && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>Desconto do clube</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{userClubDiscountFormatted}</div>
            </Skeleton>
          </div>
        )}
        {loyaltyRewardFormatted && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>{loyalty_programme?.name}</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{loyaltyRewardFormatted}</div>
            </Skeleton>
          </div>
        )}
        {loyaltyGiftFormatted && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>{loyalty_programme?.name} para novos clientes</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{loyaltyGiftFormatted}</div>
            </Skeleton>
          </div>
        )}
        {(selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
          selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX) &&
          withFees && (
            <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-20 h-4 rounded-lg"
              >
                <div>Taxa de serviço</div>
              </Skeleton>
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-12 h-4 rounded-lg"
              >
                <div>{valueFeesFormatted}</div>
              </Skeleton>
            </div>
          )}
        {(marginValue || getMarginTax() > 0) && (
          <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Margem de variação de peso</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {marginValue != null
                  ? currencyFormat(marginValue)
                  : currencyFormat(getMarginTax())}
              </div>
            </Skeleton>
          </div>
        )}
        <div className="flex flex-row justify-between text-sm font-semibold text-left w-full">
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-10 h-4 rounded-lg"
          >
            <div className="flex items-center">
              <span className="mr-1">{`Total${
                hasProductPerKilo ? ' estimado' : ''
              }`}</span>
              {hasProductPerKilo && (
                <Tooltip text="O valor dos produtos por peso pode variar de acordo com o peso medido no envio. Estabelecemos essa margem para garantir que você não pague nada além do combinado. Se o peso final for menor que o inicialmente cobrado, faremos o estorno da diferença.">
                  <HelpIcon className="text-gray-400" />
                </Tooltip>
              )}
            </div>
          </Skeleton>

          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-12 h-4 rounded-lg"
          >
            <div>{currencyFormat(totalWithFees)}</div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};
