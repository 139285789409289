import App from './App';
import PHProvider from './components/PHProvider';
import { queryClient } from './services/queryClient';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'config/global.css';
import './styles.css';

Sentry.init({
  dsn: import.meta.env.VITE_REACT_APP_SENTRY_URL,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PHProvider>
      <QueryClientProvider client={queryClient}>
        <FpjsProvider
          loadOptions={{
            apiKey: 'egAi4h2sto4tVTja0KnT',
          }}
        >
          <App />
        </FpjsProvider>
      </QueryClientProvider>
    </PHProvider>
  </React.StrictMode>,
);
