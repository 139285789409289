import { yupResolver } from '@hookform/resolvers/yup';
import {
  CustomerForm,
  ICustomerForm,
  customerValidationSchema,
} from '@onbeef/components/customerForm';
import {
  LoadingIcon,
  Toast,
  formatBirthday,
  mergeSchema,
} from '@onbeefapp/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';
import { useRegisterCustomer } from '../../queries/signup';
import { AxiosError } from 'axios';
import { Skeleton } from '@onbeef/components/skeleton';
import { useAuthStore } from '../../stores/login';
import HeaderBar from '../../components/Headers/HeaderBar';
import { Helmet } from 'react-helmet';

const validationSchema = mergeSchema(customerValidationSchema);

export const Signup: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const { mutateAsync: registerCustomer, isLoading: registerLoading } =
    useRegisterCustomer();

  const methods = useForm<ICustomerForm>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (formData: ICustomerForm) => {
    try {
      if (formData.password !== formData.password_confirmation) {
        Toast.error('As senhas não coincidem!');
        return;
      }

      const res = await registerCustomer({
        email: formData.email!,
        name: formData.name!,
        birthday: formatBirthday(formData.birthday),
        gender: formData.gender,
        password: formData.password!,
        password_confirmation: formData.password_confirmation!,
        phone: formData.phone!,
        taxpayer_id_number: formData.taxpayer_id_number!,
      });
      res.message && Toast.success(res.message);
      if (res.data?.auth) {
        setAuthState(true, res.data.auth);
      }
      navigate('/');
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center vertical-center px-6 py-8">
      <Helmet>
        <meta name="title" content={`Criar conta ${merchant?.name}`} />
      </Helmet>
      <div className="w-full max-w-md space-y-2 flex flex-col">
        <HeaderBar></HeaderBar>
        <Skeleton isLoading={isLoading} className="h-20 w-20 rounded-full mb-6">
          <img
            alt="Logo"
            className="h-20 w-20 mb-6 flex rounded-full"
            height="32"
            src={merchant?.logo}
            style={{
              aspectRatio: '32/32',
              objectFit: 'cover',
            }}
            width="32"
          />
        </Skeleton>
        <Skeleton
          isLoading={isLoading}
          repeatQuantity={2}
          className="w-full h-8 rounded-lg"
        >
          <h2 className="text-3xl font-bold mb-6">Seja bem-vindo!</h2>
          <span>Crie sua conta gratuitamente</span>
        </Skeleton>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col space-y-3"
          >
            <Skeleton
              isLoading={isLoading}
              repeatQuantity={10}
              className="w-full h-10 rounded-lg"
            >
              <CustomerForm />
              <button
                type="submit"
                className="w-full bg-primary text-contrastText flex items-center justify-center px-6 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {registerLoading ? <LoadingIcon /> : 'Criar conta'}
              </button>
              <span>
                Já tem uma conta?{' '}
                <Link to="/login" className="underline">
                  Fazer login
                </Link>
              </span>
            </Skeleton>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
