import {
  Address,
  estados,
  maskCreditCard,
  maskExpiryDate,
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskOnlyNumbers,
} from '@onbeefapp/constants'
import getAddressInfo from '@onbeefapp/constants/src/utils/GoogleAddressUtils'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Select, TextInput } from '../input'
import { AddressSearch } from '../../../apps/catalogo/src/components/Forms/AddressSearch'
import Cards, { ReactCreditCardProps } from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

export interface CreditCardFormProps {
  address?: Address
  withoutAddress?: boolean
  withoutInfo?: boolean
  withoutEmail?: boolean
  onlyAddress?: boolean
  active?: boolean
  addressDisabled?: boolean
  cardBrandCallback?: (brand: string) => void
}

export interface ICreditCardForm {
  card: {
    without_info: boolean
    without_email: boolean
    credit_card_active: boolean
    name: string
    number: string
    expiration: string
    cvv: string
    email?: string
    taxpayer_id_number?: string
  }
  billing_address: {
    without: boolean
    street_name: string
    street_number: string
    neighborhood: string
    city: string
    state: string
    country: string
    zip: string
    latitude: string
    longitude: string
    street_complement?: string
    street_reference?: string
  }
}

export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  address = undefined,
  withoutAddress = false,
  withoutInfo = true,
  withoutEmail = false,
  onlyAddress = false,
  active = false,
  addressDisabled = false,
  cardBrandCallback,
}) => {
  const methods = useFormContext<ICreditCardForm>()
  const { errors } = methods.formState

  const [focused, setFocused] =
    React.useState<ReactCreditCardProps['focused']>('name')

  React.useEffect(() => {
    let without = false
    methods.setValue('card.credit_card_active', onlyAddress ? false : active)
    // only set set if field is untouched
    if (!methods.formState.touchedFields.billing_address?.without)
      if (withoutAddress) {
        without = true
      } else if (active) {
        without = address != null
      } else {
        without = true
      }

    methods.setValue('billing_address.without', !onlyAddress && without)
    methods.setValue('card.without_info', onlyAddress ? true : withoutInfo)
    methods.setValue('card.without_email', withoutEmail)
  }, [active, address, withoutAddress, withoutInfo])

  React.useEffect(() => {
    if (address) {
      methods.setValue('billing_address.street_name', address?.street_name)
      methods.setValue('billing_address.zip', address?.zip)
      methods.setValue('billing_address.city', address?.city)
      methods.setValue('billing_address.neighborhood', address?.neighborhood)
      methods.setValue('billing_address.country', address?.country)
      methods.setValue('billing_address.state', address?.state)
      methods.setValue(
        'billing_address.street_number',
        address?.street_number || ''
      )
      methods.setValue('billing_address.latitude', address?.latitude || '')
      methods.setValue('billing_address.longitude', address?.longitude || '')
      methods.setValue(
        'billing_address.street_complement',
        address?.street_complement
      )
      methods.setValue(
        'billing_address.street_reference',
        address?.street_reference
      )
    }
  }, [address])

  const withoutBillingAddress = methods.watch('billing_address.without')

  const cvv = methods.watch('card.cvv')
  const exp = methods.watch('card.expiration')
  const cardName = methods.watch('card.name')
  const cardNumber = methods.watch('card.number')

  return (
    active && (
      <div className="w-full flex flex-col mt-3 space-y-2">
        {!onlyAddress && (
          <>
            <div className="mb-3">
              <Cards
                cvc={cvv || ''}
                expiry={exp || ''}
                focused={focused}
                name={cardName || ''}
                number={cardNumber || ''}
                locale={{ valid: 'valido até' }}
                placeholders={{ name: 'SEU NOME AQUI' }}
                callback={({ issuer }) => {
                  cardBrandCallback && cardBrandCallback(issuer)
                }}
              />
            </div>
            <div className="rounded-md">
              <TextInput
                name="card.name"
                customLabel="Nome no cartão"
                type={'text'}
                autoComplete="card.name"
                maxLength={29}
                error={errors.card?.name != null}
                onFocus={() => setFocused('name')}
              />
              {errors.card?.name && (
                <span className="text-red-400 text-sm">
                  {errors.card.name.message}
                </span>
              )}
            </div>
            <div className="rounded-md">
              <TextInput
                name="card.number"
                customLabel="Número do cartão"
                inputMode="numeric"
                maxLength={19}
                type={'text'}
                autoComplete="card.number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue(
                    'card.number',
                    maskCreditCard(e.target.value)
                  )
                  methods.trigger('card.number')
                }}
                error={errors.card?.number != null}
                onFocus={() => setFocused('number')}
              />
              {errors.card?.number && (
                <span className="text-red-400 text-sm">
                  {errors.card.number.message}
                </span>
              )}
            </div>
            <div className="flex flex-row space-x-2">
              <div className="w-full rounded-md">
                <TextInput
                  name="card.expiration"
                  customLabel="Data de expiração"
                  autoComplete="card.expiration"
                  inputMode="numeric"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    methods.setValue(
                      'card.expiration',
                      maskExpiryDate(e.target.value)
                    )
                    methods.trigger('card.expiration')
                  }}
                  error={errors.card?.expiration != null}
                  onFocus={() => setFocused('expiry')}
                />
                {errors.card?.expiration && (
                  <span className="text-red-400 text-sm">
                    {errors.card.expiration.message}
                  </span>
                )}
              </div>
              <div className="w-full rounded-md">
                <TextInput
                  name="card.cvv"
                  customLabel="CVV"
                  inputMode="numeric"
                  autoComplete="cvv"
                  maxLength={4}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    methods.setValue(
                      'card.cvv',
                      maskOnlyNumbers(e.target.value)
                    )
                    methods.trigger('card.cvv')
                  }}
                  error={errors.card?.cvv != null}
                  onFocus={() => setFocused('cvc')}
                  onBlur={() => setFocused('name')}
                />
                {errors.card?.cvv && (
                  <span className="text-red-400 text-sm">
                    {errors.card.cvv.message}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
        {!withoutInfo && (
          <div className="flex flex-row space-x-2">
            {!withoutEmail && (
              <div className="w-full rounded-md">
                <TextInput
                  name="card.email"
                  customLabel="E-mail do titular"
                  type={'text'}
                  autoComplete="email"
                  error={errors.card?.email != null}
                />
                {errors.card?.email && (
                  <span className="text-red-400 text-sm">
                    {errors.card.email.message}
                  </span>
                )}
              </div>
            )}
            <div
              className={`w-full rounded-md ${!withoutEmail ? '' : 'w-full'}`}
            >
              <TextInput
                name="card.taxpayer_id_number"
                customLabel="CPF/CNPJ do titular"
                type={'string'}
                inputMode="numeric"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length <= 14) {
                    methods.setValue(
                      'card.taxpayer_id_number',
                      maskCPF(e.target.value)
                    )
                  } else {
                    methods.setValue(
                      'card.taxpayer_id_number',
                      maskCNPJ(e.target.value)
                    )
                  }
                  methods.trigger('card.taxpayer_id_number')
                }}
                autoComplete="taxpayer_id_number"
                error={errors.card?.taxpayer_id_number != null}
              />
              {errors.card?.taxpayer_id_number && (
                <span className="text-red-400 text-sm">
                  {errors.card.taxpayer_id_number.message}
                </span>
              )}
            </div>
          </div>
        )}

        {!onlyAddress && address != null && (
          <div>
            <Checkbox
              name="billing_address.without"
              customLabel="Endereço é o mesmo de entrega"
            />
          </div>
        )}
        {!withoutBillingAddress && (
          <>
            <div className="w-full">
              <div
                className={`w-full rounded-md flex flex-col gap-1 ${
                  errors.billing_address?.street_name != null
                    ? 'text-red-400'
                    : ''
                }`}
              >
                <span>Endereço</span>
                {addressDisabled ? (
                  <TextInput
                    name="billing_address.street_name"
                    className="mt-2"
                    disabled
                  />
                ) : (
                  <>
                    <AddressSearch
                      handleSelectedAddress={(place) => {
                        const latitude = String(place.geometry.location.lat())
                        const longitude = String(place.geometry.location.lng())
                        const result = getAddressInfo(place.address_components)
                        methods.setValue('billing_address.latitude', latitude)
                        methods.setValue('billing_address.longitude', longitude)
                        methods.setValue(
                          'billing_address.neighborhood',
                          result.neighborhood
                        )
                        methods.setValue('billing_address.city', result.city)
                        methods.setValue('billing_address.state', result.state)
                        methods.setValue('billing_address.zip', result.zipCode)
                        methods.setValue('billing_address.country', 'Brasil')
                        methods.setValue(
                          'billing_address.street_name',
                          result.streetName || place.name || ''
                        )
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="w-full mt-2 rounded-md">
              <TextInput
                name="billing_address.street_name"
                customLabel="Rua"
                validationSchema={{}}
                type={'text'}
                autoComplete="rua"
                error={errors.billing_address?.street_name != null}
                disabled={addressDisabled}
              />
              <TextInput
                name="billing_address.neighborhood"
                customLabel="Bairro"
                validationSchema={{}}
                type={'text'}
                autoComplete="bairro"
                error={errors.billing_address?.neighborhood != null}
                disabled={addressDisabled}
              />
              {errors.billing_address?.neighborhood && (
                <span className="text-red-400 text-sm">
                  {errors.billing_address.neighborhood.message}
                </span>
              )}
            </div>
            <div className="flex flex-row w-full items-center space-x-2">
              <div className="w-full rounded-md">
                <TextInput
                  name="billing_address.city"
                  customLabel="Cidade"
                  type={'text'}
                  autoComplete="cidade"
                  className="h-[42px] !min-w-0 w-full text-sm"
                  error={errors.billing_address?.city != null}
                  disabled={addressDisabled}
                />
                {errors.billing_address?.city && (
                  <span className="text-red-400 text-sm">
                    {errors.billing_address.city.message}
                  </span>
                )}
              </div>
              <div className="w-2/3 rounded-md">
                <Select
                  className={`flex items-center h-[40px] mt-7 pl-2 border-[1px] border-gray-400 rounded-md !min-w-0 w-full text-sm bg-white ${
                    errors.billing_address?.state ? 'border-red-500' : ''
                  }`}
                  placeholder={'Selecione um estado'}
                  options={estados.map((estado) => {
                    return {
                      label: estado.estado,
                      value: estado.id,
                    }
                  })}
                  id={'billing_address.state'}
                  name={'billing_address.state'}
                  disabled={addressDisabled}
                />
                <div className="text-red-400 text-xs">
                  {errors?.billing_address?.state &&
                    errors.billing_address?.state.message}
                </div>
              </div>
            </div>
            <div className="w-full rounded-md">
              <TextInput
                name="billing_address.zip"
                customLabel="CEP"
                type={'text'}
                autoComplete="CEP"
                inputMode="numeric"
                className="h-[42px] text-sm"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue(
                    'billing_address.zip',
                    maskCEP(e.target.value)
                  )
                  methods.trigger('billing_address.zip')
                }}
                error={errors.billing_address?.zip != null}
                disabled={addressDisabled}
              />
              <div className="text-red-400 text-xs">
                {errors?.billing_address?.zip &&
                  errors.billing_address?.zip.message}
              </div>
            </div>
            <div className="w-full rounded-md">
              <TextInput
                name="billing_address.street_number"
                customLabel="Número"
                type={'number'}
                autoComplete="numero"
                onWheel={(event: any) => event.currentTarget.blur()}
                className="h-[42px] !min-w-0 w-full text-sm"
                error={errors.billing_address?.street_number != null}
                disabled={addressDisabled}
              />
              <div className="text-red-400 text-xs">
                {errors?.billing_address?.street_number &&
                  errors.billing_address?.street_number.message}
              </div>
            </div>
            <div className="w-full rounded-md">
              <TextInput
                name="billing_address.street_complement"
                customLabel="Complemento"
                type={'text'}
                autoComplete="complemento"
                className="h-[42px] text-sm"
                disabled={addressDisabled}
              />
            </div>
          </>
        )}
      </div>
    )
  )
}
