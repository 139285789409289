import { TextInput } from '@onbeef/components/input';
import {
  AlertIcon,
  DeliveryType,
  EditIcon,
  PaymentMethodType,
  ProductType,
  WherePaymentType,
  maskCPF,
  maskPhone,
} from '@onbeefapp/constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OrderDetailsContext } from '../../../contexts/OrderDetailsContext';
import { useSlug } from '../../../hooks/useSlug';
import OptionsApp from '../../../pages/PaymentMethods/Options/OptionsApp';
import OptionsDelivery from '../../../pages/PaymentMethods/Options/OptionsDelivery';
import { useMerchantCache } from '../../../queries/home';
import { useCartStore } from '../../../stores/cart';
import { useAuthStore } from '../../../stores/login';
import { useMerchantStore } from '../../../stores/merchant';
import { useOrderDetails } from '../../../stores/order-details';
import { useUserStore } from '../../../stores/user';
import CardWherePayment from '../../Cards/CardWherePayment';
import Skeleton from '../../Skeleton/Skeleton';
import { CheckoutCreditCard } from './CheckoutCreditCard';
import { CheckoutSummary } from './CheckoutSummary';

interface CheckoutInfo {
  name: string;
  phone: string;
  document?: string;
  email: string;
}

interface FormCustomerCheckoutInfoProps {
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

export const FormCustomerCheckoutInfo: React.FC<
  FormCustomerCheckoutInfoProps
> = ({ setExtensionCustomCouponCode, setExtensionCustomDeliveryFee }) => {
  const navigate = useNavigate();

  const {
    isExtension,
    isCheckoutLink,
    linkOrderDetails,
    products,
    crmBonusData,
  } = useCartStore((state) => ({
    isExtension: state.isExtension,
    isCheckoutLink: state.isCheckoutLink,
    linkOrderDetails: state.linkOrderDetails,
    products: state.products,
    crmBonusData: state.crmBonusData,
  }));

  const {
    deliveryType,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setWherePaymentType,
    wherePaymentType,
  } = useOrderDetails((state) => ({
    deliveryType: state.deliveryType,
    selectedPaymentMethod: state.selectedPaymentMethod,
    setWherePaymentType: state.setWherePaymentType,
    setSelectedPaymentMethod: state.setSelectedPaymentMethod,
    wherePaymentType: state.wherePaymentType,
  }));

  const slugParams = useSlug();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const authPhone = useAuthStore((state) => state.user?.phone);

  const { phone, setName, setTaxPayerIdNumber, setPhone, setEmail } =
    useUserStore((state) => ({
      phone: state.phone,
      setName: state.setName,
      setTaxPayerIdNumber: state.setTaxPayerIdNumber,
      setPhone: state.setPhone,
      setEmail: state.setEmail,
    }));

  const methods = useFormContext<CheckoutInfo>();

  if (!methods) {
    return null;
  }

  const errors = methods.formState.errors;

  const {
    merchant,
    payment_methods,
    haveBank,
    haveDeliveryPaymentMethods,
    onlyUnitProducts,
    activeCheckoutLink,
  } = useMerchantStore((state) => ({
    merchant: state.merchant,
    payment_methods: state.payment_methods,
    haveBank: state.haveBank,
    haveDeliveryPaymentMethods: state.haveDeliveryPaymentMethods,
    onlyUnitProducts: state.onlyUnitProducts,
    activeCheckoutLink: state.activeCheckoutLink,
  }));
  const { isLoading: isLoadingMerchant } = useMerchantCache(slugParams.slug);

  const noHaveOnlyUnitProducts = Object.values(products).find(
    (product) => product.type === ProductType.KG,
  )
    ? true
    : false;

  const {
    rewardAvailable,
    loyaltyRewardFormatted,
    loyaltyGiftFormatted,
    isACombinar,
  } = React.useContext(OrderDetailsContext);

  const withWppPixOrCheckouLink =
    Boolean(
      payment_methods?.find(
        (item) =>
          item.name.includes('Whatsapp') &&
          item.pivot &&
          item.pivot.status === 1,
      ),
    ) || activeCheckoutLink;
  const payForApp =
    !isExtension &&
    (isCheckoutLink ||
      (onlyUnitProducts
        ? !noHaveOnlyUnitProducts
        : isACombinar
          ? withWppPixOrCheckouLink
          : haveBank || withWppPixOrCheckouLink));

  const handleChangeWherePayment = (type: WherePaymentType) => {
    setWherePaymentType(type);
    setSelectedPaymentMethod(undefined);
  };

  const handleEditCrmPhone = () => {
    navigate('/cart', { state: { change_crm_phone: true }, replace: true });
  };

  React.useEffect(() => {
    if (payForApp && !haveDeliveryPaymentMethods) {
      setWherePaymentType(WherePaymentType.APP);
    }
    if (haveDeliveryPaymentMethods && !payForApp) {
      setWherePaymentType(WherePaymentType.WITHDRAWALORDELIVERY);
    }
  }, []);

  React.useEffect(() => {
    if (isExtension) return;
    if (isAuthenticated && authPhone && authPhone.length > 9) {
      methods.setValue('phone', maskPhone(authPhone));
    }
  }, [isAuthenticated, authPhone]);

  const withCrmPhone = !isAuthenticated && crmBonusData && phone?.length > 9;

  return (
    <form
      className={`w-full p-2 transition-all ${!isExtension ? 'pb-60' : ''}`}
      id="checkoutScreen"
    >
      <Skeleton isLoading={isLoadingMerchant} className="h-6 rounded-lg w-28">
        <div className="text-base font-semibold text-left mb-3">
          {!isExtension ? 'Seus dados' : 'Dados do cliente'}
        </div>
      </Skeleton>
      <div className="w-full mt-2 rounded-md">
        <Skeleton
          isLoading={isLoadingMerchant}
          className="w-full rounded-md h-11"
        >
          <TextInput
            customLabel="Nome completo"
            type={'text'}
            name="name"
            validationSchema={{}}
            autoComplete="name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setValue('name', e.target.value);
              setName(e.target.value);
              methods.trigger('name');
            }}
            error={errors?.name !== undefined}
            disabled={isCheckoutLink}
          />
          <div className="text-red-400 text-xs">
            {errors?.name && errors?.name?.message}
          </div>
        </Skeleton>
      </div>
      <div className="w-full mt-2 rounded-md">
        <Skeleton
          isLoading={isLoadingMerchant}
          className="w-full rounded-md h-11"
        >
          <TextInput
            name="phone"
            customLabel="Celular"
            type={'text'}
            inputMode="numeric"
            autoComplete="phone"
            maxLength={15}
            placeholder="(00) 00000-0000"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setValue('phone', maskPhone(e.target.value));
              setPhone(maskPhone(e.target.value));
              methods.trigger('phone');
            }}
            error={errors?.phone !== undefined}
            disabled={
              (isAuthenticated && authPhone != null
                ? authPhone?.length > 9
                : false) ||
              isCheckoutLink ||
              withCrmPhone
            }
            endIcon={
              withCrmPhone ? (
                <button
                  type="button"
                  className="mr-2"
                  onClick={handleEditCrmPhone}
                >
                  <EditIcon />
                </button>
              ) : undefined
            }
          />
          <div className="text-red-400 text-xs">
            {errors?.phone && errors?.phone?.message}
          </div>
        </Skeleton>
      </div>

      {/* Caso seja cartão de crédito ou PIX pago pelo app, solicitar E-mail e CPF do usuário */}

      {merchant?.cpf_cnpj_enable === 1 &&
        selectedPaymentMethod !== PaymentMethodType.CHECKOUT_CREDIT_CARD &&
        selectedPaymentMethod !== PaymentMethodType.CHECKOUT_PIX && (
          <div className="w-full mt-2rounded-md">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-full rounded-md h-11"
            >
              <TextInput
                name="document"
                customLabel="CPF"
                validationSchema={{}}
                inputMode="numeric"
                autoComplete="document"
                error={errors?.document !== undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue('document', maskCPF(e.target.value));
                  setTaxPayerIdNumber(maskCPF(e.target.value));
                  methods.trigger('document');
                }}
                disabled={
                  isCheckoutLink &&
                  !!linkOrderDetails?.customer.taxpayer_id_number
                }
              />
              <div className="text-red-400 text-xs">
                {errors?.document && errors?.document?.message}
              </div>
            </Skeleton>
          </div>
        )}

      {(selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX) && (
        <>
          <div className="w-full mt-2 rounded-md">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-full rounded-md h-11"
            >
              <TextInput
                name="document"
                customLabel="CPF"
                validationSchema={{}}
                autoComplete="document"
                error={errors?.document !== undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue('document', maskCPF(e.target.value));
                  setTaxPayerIdNumber(maskCPF(e.target.value));
                  methods.trigger('document');
                }}
                disabled={
                  isCheckoutLink &&
                  !!linkOrderDetails?.customer.taxpayer_id_number
                }
              />
              <div className="text-red-400 text-xs">
                {errors?.document && errors?.document?.message}
              </div>
            </Skeleton>
          </div>

          <div className="w-full mt-2 rounded-md">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-full rounded-md h-11"
            >
              <TextInput
                name="email"
                customLabel="E-mail"
                validationSchema={{}}
                type={'email'}
                autoComplete="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue('email', e.target.value);
                  setEmail(e.target.value);
                  methods.trigger('email');
                }}
                error={errors?.email !== undefined}
                disabled={isCheckoutLink && !!linkOrderDetails?.customer.email}
              />
              <div className="text-red-400 text-xs">
                {errors?.email && errors?.email?.message}
              </div>
            </Skeleton>
          </div>
        </>
      )}

      {/* Clube de fidelidade */}
      {!isExtension && (
        <>
          {rewardAvailable && (
            <div className="flex flex-row space-x-1 p-2 rounded-md mt-4 mb-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
              {loyaltyRewardFormatted ? (
                <>
                  <span>Benefício resgatado:</span>
                  <span className="text-green-600">
                    {loyaltyRewardFormatted}
                  </span>
                </>
              ) : (
                <span className="text-amber-600">
                  Fidelidade: o prêmio não pode ser reinvidicado nessa compra
                  devido ao uso de um cupom
                </span>
              )}
            </div>
          )}
          {loyaltyGiftFormatted && (
            <div className="flex flex-row space-x-1 p-2 rounded-md mt-4 mb-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
              <span>Benefício para novos clientes:</span>
              <span className="text-green-600">{loyaltyGiftFormatted}</span>
            </div>
          )}
        </>
      )}

      {/* Pagamento para o usuário selecionar qual deseja */}
      <div className="flex flex-col items-start justify-start w-full py-2 mt-2 space-y-1">
        <Skeleton isLoading={isLoadingMerchant} className="h-6 rounded-lg w-28">
          <span className="text-base font-semibold text-left mb-2">
            Pagamento
          </span>
        </Skeleton>
      </div>

      {!isExtension && (
        <div className="flex flex-row w-full mb-2 space-x-2 text-left">
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-40 h-5 rounded-lg"
          >
            <>
              <CardWherePayment
                className={haveDeliveryPaymentMethods ? 'w-6/12' : 'w-full'}
                setter={handleChangeWherePayment}
                value={wherePaymentType}
                show={payForApp}
                type={WherePaymentType.APP}
              >
                Pague online
              </CardWherePayment>
              <CardWherePayment
                className={payForApp ? 'w-6/12' : 'w-full'}
                setter={handleChangeWherePayment}
                value={wherePaymentType}
                show={haveDeliveryPaymentMethods}
                type={WherePaymentType.WITHDRAWALORDELIVERY}
              >
                Pague na{' '}
                {deliveryType === DeliveryType.DELIVERY
                  ? 'entrega'
                  : 'retirada'}
              </CardWherePayment>
            </>
          </Skeleton>
        </div>
      )}

      {!isExtension &&
        wherePaymentType === WherePaymentType.APP &&
        deliveryType === DeliveryType.DELIVERY && (
          <Skeleton
            isLoading={isLoadingMerchant}
            className="h-6 rounded-lg w-full mb-2"
          >
            <div className="bg-yellow-100 text-xs my-2 flex items-center border border-yellow-600 rounded-md px-4 py-2">
              <AlertIcon className="!w-5 !h-5" />
              <p className="font-medium ml-2 w-10/12">
                Não aceite nenhum tipo de cobrança no ato da entrega pelos
                entregadores!
              </p>
            </div>
          </Skeleton>
        )}

      <OptionsDelivery
        active={wherePaymentType === WherePaymentType.WITHDRAWALORDELIVERY}
        setExtensionCustomCouponCode={setExtensionCustomCouponCode}
        setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
      />
      <OptionsApp
        show={payForApp}
        active={wherePaymentType === WherePaymentType.APP}
        setter={setSelectedPaymentMethod}
        value={selectedPaymentMethod}
      />

      {selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD && (
        <Skeleton
          isLoading={isLoadingMerchant}
          className="h-20 rounded-lg w-28"
        >
          <div className="flex flex-col items-start justify-start w-full py-2 mt-2 space-y-1">
            <span className="text-base font-semibold text-left mb-2">
              Cartões
            </span>
          </div>
          <CheckoutCreditCard />
        </Skeleton>
      )}

      {!isExtension && <CheckoutSummary />}
    </form>
  );
};
