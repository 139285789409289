import { Helmet } from 'react-helmet';
import FormPrivateCoupon from '../../../components/Forms/FormPrivateCoupon';
import HeaderBar from '../../../components/Headers/HeaderBar';
import ListCoupons from '../../../components/Lists/ListCoupons';
import { Tab } from '../../../components/Tab/Tab';
import { OrderDetailsContext } from '../../../contexts/OrderDetailsContext';
import { useGetCoupons } from '../../../queries/coupon';
import { useCartStore } from '../../../stores/cart';
import { useOrderDetails } from '../../../stores/order-details';
import React from 'react';
import { useMerchantStore } from '../../../stores/merchant';

const Coupons: React.FC = () => {
  const merchant = useMerchantStore((state) => state.merchant);
  const { merchantId } = useCartStore();
  const { deliveryType } = useOrderDetails();
  const { subtotal } = React.useContext(OrderDetailsContext);

  const { data, isLoading } = useGetCoupons({
    amount: subtotal,
    merchant_id: merchantId,
    type_delivery: deliveryType,
  });

  React.useEffect(() => {}, [data]);

  const [activeTab, setActiveTab] = React.useState<number>(1);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  if (!merchantId) {
    return <></>;
  }

  return (
    <div className="flex flex-col p-2 pt-3 items-center justify-center">
      <Helmet>
        <meta name="title" content={`Cupons ${merchant?.name}`} />
        <meta
          name="description"
          content={`${data?.available.map((coupon) => coupon.name).join(', ')}`}
        />
      </Helmet>
      {/* Header */}
      <HeaderBar>
        <div className="text-sm font-normal text-center w-full">Cupons</div>
      </HeaderBar>

      <div className="flex flex-col mt-4 w-[100%] md:w-[60%] align-center">
        {/* Private Cupon */}
        <FormPrivateCoupon />

        {/* Tabs */}
        <div className="flex h-[60px] w-full mb-2">
          <Tab active={activeTab === 1} onClick={() => handleTabClick(1)}>
            <span className="text-sm text-gray-600 font-light">
              Disponíveis
            </span>
          </Tab>
          <Tab active={activeTab === 2} onClick={() => handleTabClick(2)}>
            <span className="text-sm text-gray-600 font-light">Bloqueados</span>
          </Tab>
        </div>

        <ListCoupons
          coupons={data?.available}
          open={activeTab === 1}
          isLoading={isLoading}
        />
        <ListCoupons
          coupons={data?.blocked}
          open={activeTab === 2}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Coupons;
