import { Avatar, Typography } from '@material-tailwind/react';
import { Stars } from '@onbeef/components/stars';
import {
  AddressUtils,
  DeliveryType,
  ORDER_STATUS,
  PaymentMethodType,
  Toast,
  formatOrderTime,
  pagarmeToHumanColor,
  orderStatusToHumanColor,
  orderStatusToHuman,
  debounce,
} from '@onbeefapp/constants';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrdersHeader from '../../../components/Headers/OrdersHeader';
import SkeletonOrderDetail from '../../../components/Skeleton/SkeletonOrderDetail';
import { useGetOrderDetail } from '../../../queries/my-orders';
import { useMyOrdersStore } from '../../../stores/my-orders';
import OrderDetailItems from './OrderDetailItems';
import OrderDetailPaymentValues from './OrderDetailPaymentValues';
import { getWhatsappURL } from '../../../utils/Whatsapp/WhatsappURL';
import { useAuthStore } from '../../../stores/login';
import { useGetLinkOrderDetails } from '../../../queries/checkout-link';
import { useGeneratedOrderStore } from '../../../stores/pix';

const OrderDetail: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { selectedOrder, setSelectedOrder } = useMyOrdersStore();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const generatedOrder = useGeneratedOrderStore(
    (state) => state.generatedOrder,
  );
  const { mutateAsync: getOrderDetail, isLoading: isLoadingGetOrderDetail } =
    useGetOrderDetail();
  const {
    mutateAsync: getUnloggedOrderDetails,
    isLoading: isLoadingGetUnloggedOrderDetails,
  } = useGetLinkOrderDetails();

  const openMenu = () => navigate('/');

  const rate = (stars: number) => {
    navigate(`/orders/${params.id}/rate`, { state: { stars } });
  };

  const openAssessmentDetail = () => {
    if (selectedOrder?.assessment) {
      navigate(`/orders/${params.id}/rate`);
    }
  };

  const sendWhatsapp = async () => {
    if (!selectedOrder) return;

    const url = await getWhatsappURL(
      selectedOrder.merchant.phone,
      selectedOrder.id,
      selectedOrder.code,
    );
    window.open(url);
  };

  const fetchOrderData = async (id: string) => {
    try {
      let data;
      if (isAuthenticated) {
        data = await getOrderDetail(id);
      } else {
        const code = generatedOrder[id]?.code;
        data = await getUnloggedOrderDetails({ orderID: id, code: code! });
      }
      data && setSelectedOrder(data);
    } catch (error) {
      const message =
        (error as any).response?.data?.message ||
        'Não foi possível encontrar o pedido';
      Toast.error(message);
      navigate(`/orders`, { replace: true });
    }
  };

  useEffect(() => {
    if (!params.id) return;
    fetchOrderData(params.id);
  }, [params.id]);

  const isLoading = isLoadingGetOrderDetail || isLoadingGetUnloggedOrderDetails;

  if (!params.id) {
    navigate(`/orders`);
    return <></>;
  }

  return (
    <div className={'flex flex-col p-2'}>
      <OrdersHeader title="Detalhes do pedido" back_url={`/orders`} />
      <div className="flex items-center justify-center">
        {isLoading || !selectedOrder ? (
          <SkeletonOrderDetail />
        ) : (
          <div className="flex flex-col p-4 w-[100%] md:w-[60%] align-center space-y-4 text-sm">
            <div className="flex items-center space-x-6">
              <Avatar
                placeholder={'store logo'}
                src={selectedOrder.merchant.logo}
                className="w-20 h-20"
              />
              <div className="flex flex-col">
                <p className="font-medium text-base tracking-wide">
                  {selectedOrder.merchant.name}
                </p>
                <p className="font-light text-sm tracking-wide">
                  {formatOrderTime(selectedOrder.created_at)}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm">Avalie seu pedido</p>
              <div
                className="flex items-center justify-center"
                onClick={openAssessmentDetail}
              >
                <Stars
                  value={
                    selectedOrder.assessment
                      ? selectedOrder.assessment.rating
                      : 0
                  }
                  handler={rate}
                  readonly={!!selectedOrder.assessment}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <button
                className="w-full p-2 border border-[var(--color-primary)] rounded-lg text-primary font-medium"
                onClick={openMenu}
              >
                Ver cardápio
              </button>
              <button
                className="w-full p-2 border border-[var(--color-primary)] bg-primary rounded-lg text-contrastText font-medium"
                onClick={sendWhatsapp}
              >
                Chamar loja no Whatsapp
              </button>
            </div>
            <div className="flex flex-col space-y-2 divide-y divide-gray-300">
              <div className="flex flex-col space-y-2">
                <p className="font-semibold text-sm">
                  Pedido #{selectedOrder.code}
                </p>
                <OrderDetailItems />
              </div>
              <div className="pt-2">
                <p className="font-semibold text-sm">Resumo de valores</p>
                <OrderDetailPaymentValues />
              </div>
              <div className="flex flex-col pt-2">
                <div className="flex items-center w-full">
                  <p className="w-[40%] font-semibold text-sm">
                    Status do pedido
                  </p>
                  <div className="w-[60%] flex items-center justify-end">
                    <span
                      className="dot"
                      style={{
                        height: '10px',
                        width: '10px',
                        marginLeft: '5px',
                        marginRight: '5px',
                        backgroundColor:
                          orderStatusToHumanColor[selectedOrder.status!]?.color,
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {orderStatusToHuman(
                      selectedOrder.status!,
                      selectedOrder.type_delivery,
                    )}
                  </div>
                </div>
                {selectedOrder.status_payment != null && (
                  <div className="flex items-center w-full">
                    <Typography
                      variant="small"
                      className="w-[40%] font-medium"
                      placeholder={'status pagamento'}
                    >
                      Status do pagamento
                    </Typography>
                    <div className="w-[60%] flex items-center justify-end">
                      <span
                        className="dot"
                        style={{
                          height: '10px',
                          width: '10px',
                          marginLeft: '5px',
                          marginRight: '5px',
                          backgroundColor:
                            pagarmeToHumanColor[
                              selectedOrder.status_payment?.toLowerCase() ?? ''
                            ]?.color,
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}
                      ></span>
                      {
                        pagarmeToHumanColor[
                          selectedOrder.status_payment?.toLowerCase() ?? ''
                        ]?.label
                      }
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between pt-2">
                <p className="w-[40%] font-semibold text-sm">Pagamento</p>
                <p className="w-[60%] font-medium text-end pr-1">
                  {selectedOrder.label_method_payment}
                </p>
              </div>
              <div className="pt-2">
                {selectedOrder.type_delivery === DeliveryType.DELIVERY ? (
                  <>
                    <div>
                      <p className="font-semibold text-sm">
                        Endereço de entrega
                      </p>
                    </div>
                    <div>
                      <p className="font-medium">
                        <b>
                          {AddressUtils.getFormattedAddress(
                            selectedOrder.address,
                          )}
                        </b>
                      </p>
                    </div>
                  </>
                ) : selectedOrder.type_delivery === DeliveryType.UBER ? (
                  <div className="flex flex-col  space-y-2">
                    <p className="font-semibold mb-3 text-sm">
                      Acompanhe sua entrega no link abaixo:{' '}
                    </p>
                    <a
                      href={selectedOrder?.delivery?.tracking_url || '#'}
                      onClick={(e) => {
                        if (!selectedOrder?.delivery?.tracking_url) {
                          e.preventDefault();
                          Toast.error(
                            'Não foi possível encontrar o link de rastreamento',
                          );
                        }
                      }}
                      target="_blank"
                      rel="noreferrer"
                      className="w-full text-center p-2 border border-blue-gray-800 rounded-lg text-black font-medium"
                    >
                      Status do entregador com seu pedido
                    </a>
                  </div>
                ) : (
                  <div>
                    <p className="font-medium text-sm">
                      Pedido para retirada na loja
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
