import {
  AddIcon,
  DeleteIcon,
  MinusIcon,
  ProductType,
  currencyFormat,
  formatPackageQuantityComments,
} from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoPhoto from '../../assets/images/no-photos.png';
import { ProductCartType, useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useDrawerStore } from '../../stores/drawer';
import { useKitsStore } from '../../stores/kits';
import { useMerchantStore } from '../../stores/merchant';
import { getSelectedCutDetail } from '../../utils/CutsUtils';
import { getQuantity } from '../../utils/ProductUtils';
import SkeletonProductCart from '../Skeleton/SkeletonProductCart';

type CardHorizontalProductProps = {
  product: ProductCartType;
};

const CardHorizontalProduct: React.FC<CardHorizontalProductProps> = ({
  product,
}) => {
  const { products, increaseQuantity, removeProduct, isExtension } =
    useCartStore((state) => ({
      addCart: state.addCart,
      products: state.products,
      increaseQuantity: state.increaseQuantity,
      removeProduct: state.removeProduct,
      setCart: state.setCart,
      isExtension: state.isExtension,
    }));

  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  const { toggleDrawer, setProductDrawer, setCutID } = useDrawerStore(
    (state) => ({
      toggleDrawer: state.toggleDrawer,
      setProductDrawer: state.setProductDrawer,
      setCutID: state.setCutID,
    }),
  );

  const { getComplements, getSumTotalQuantityComplements } =
    useComplementsStore((state) => ({
      getComplements: state.getComplements,
      getSumTotalQuantityComplements: state.getSumTotalQuantityComplements,
    }));

  const getKitItems = useKitsStore((state) => state.getKitItems);

  const kitItems = getKitItems(product.id);
  const { formatted: formattedQuantity, quantity: quantity } = getQuantity(
    products,
    product,
  );

  const quantityProduct =
    product.product_type === ProductType.KG
      ? formattedQuantity
      : `${formattedQuantity}x`;

  const navigate = useNavigate();
  const complements = getComplements(product.id);

  const handleClickGrams = () => {
    setProductDrawer(product);
    toggleDrawer('drawerGrams');
    if (product.cuts.length === 1) {
      setCutID(product.cuts[0]!.id);
    }
  };

  const navigateProduct = () => {
    if (!isExtension) {
      navigate(`/product/${product.slug}`);
    }
  };

  const minusProduct = () => {
    if (product.openModal && product.hasCuts) {
      navigateProduct();
      return;
    }

    if (product.product_type === ProductType.KG) {
      handleClickGrams();
      return;
    }

    if (complements.length > 0) {
      navigateProduct();
      return;
    }

    removeProduct(product.id);
  };

  const addProduct = () => {
    if (product.openModal && product.hasCuts) {
      navigateProduct();
      return;
    }

    if (product.product_type === ProductType.KG) {
      handleClickGrams();
      return;
    }

    if (complements.length > 0) {
      navigateProduct();
      return;
    }

    increaseQuantity(product.id);
  };

  return (
    <SkeletonProductCart>
      <div className="flex flex-row w-full h-auto items-center last:border-0 mt-1 border-b-[1px] border-gray-200 pb-2">
        <div className="flex flex-row space-x-2 w-full">
          {product?.media?.[0] && product.media[0].file_url && (
            <img
              className="object-cover w-[70px] h-[70px] rounded-lg"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = merchant?.logo || NoPhoto;
              }}
              onClick={navigateProduct}
              src={product.media[0].file_url}
              alt="Imagem do produto"
              loading="lazy"
            />
          )}
          <div className="flex flex-row justify-between xl:px-2 items-start w-full">
            <div className="flex flex-col space-y-1 w-[80%] items-start gap-1">
              <div className="font-bold text-sm" onClick={navigateProduct}>
                {product.name} {isExtension && `- ${quantityProduct}`}
              </div>
              <div className="flex flex-col items-start gap-1">
                {product.type === ProductType.KIT &&
                  product.kit_type != null &&
                  kitItems && (
                    <div className="flex flex-col font-semibold text-xs text-[#828282]">
                      {Object.keys(kitItems).map((catID) => {
                        const items = kitItems[catID]!;
                        return (
                          <React.Fragment key={catID}>
                            {items.map((item) => {
                              return (
                                <span key={item.id}>
                                  {item.formatted_quantity} {item.label}
                                </span>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                {product.selectedCuts.map((cut) => {
                  const cutDetail = getSelectedCutDetail(product.id, cut.id);
                  return (
                    <button
                      type="button"
                      onClick={navigateProduct}
                      className="flex flex-col font-semibold text-xs text-[#828282] text-left"
                    >
                      {cutDetail?.quantity}g {cutDetail?.name}
                      {(cutDetail?.comments || cutDetail?.package_quantity) && (
                        <span className="text-left text-[#aaaaaa]">
                          {formatPackageQuantityComments(
                            cutDetail.comments,
                            cutDetail.package_quantity,
                          )}
                        </span>
                      )}
                    </button>
                  );
                })}
                {complements.map((complement) => (
                  <button
                    key={complement.id}
                    className="font-semibold text-xs text-[#828282]"
                    onClick={navigateProduct}
                  >
                    {product.hasCuts && complement.items_kg === 1
                      ? `${complement.totalQuantity}g - ${complement.name}`
                      : `${complement.totalQuantity}x - ${complement.name}` +
                        ((complement.price ?? 0) > 0
                          ? ` (${currencyFormat(
                              complement.totalQuantity *
                                (complement.price ?? 0),
                            )})`
                          : '')}
                  </button>
                ))}
              </div>
              <div className="flex flex-row w-full justify-between">
                <div className="w-full flex flex-col items-start">
                  <button
                    type="button"
                    className="font-bold flex flex-col text-xs gap-y-1"
                    onClick={navigateProduct}
                  >
                    {product.hasClubDiscount
                      ? currencyFormat(
                          (product.clubPrice ?? 0) *
                            (product.totalAmount ?? 0) +
                            getSumTotalQuantityComplements(product.id),
                        )
                      : currencyFormat(product.calculatedValue)}
                  </button>
                </div>
              </div>
              <div className="font-semibold text-xs text-[#858585]">
                {formatPackageQuantityComments(
                  product.comments,
                  product.package_quantity,
                )}
              </div>
            </div>
            {!isExtension && (
              <div className="flex flex-col gap-2 w-32 h-full items-center justify-center">
                {product.product_type === ProductType.KG && (
                  <div className="flex flex-col gap-1 w-full font-semibold text-center">
                    <span className="text-xs text-red-600 uppercase">
                      Peso variável
                    </span>
                    <span className="text-xs">
                      Preço varia de acordo com peso medido no envio
                    </span>
                  </div>
                )}
                <div className="flex flex-row space-x-2 items-center w-full justify-between">
                  <button
                    className={`rounded-full bg-primary h-6 w-6 text-contrastText`}
                    onClick={minusProduct}
                  >
                    <center>
                      {quantity > 1 ? <MinusIcon /> : <DeleteIcon />}
                    </center>
                  </button>
                  <div className="font-bold text-lg">{formattedQuantity}</div>
                  <button
                    className={`rounded-full bg-primary h-6 w-6 text-contrastText`}
                    onClick={addProduct}
                  >
                    <center>
                      <AddIcon />
                    </center>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SkeletonProductCart>
  );
};

export default CardHorizontalProduct;
