import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import {
  LoginResponse,
  NewPasswordResponse,
  ResetPasswordResponse,
} from './types';

async function loginCustomer({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const { data } = await api.post<LoginResponse>('/login', {
    email,
    password,
    profile: 'api_customer',
  });
  return data;
}

async function forgotPassword(email: string) {
  if (!email) {
    throw new Error('Email is required');
  }
  const request = await api.post<ResetPasswordResponse>(
    '/customers/forgot-password',
    {
      email,
    },
  );
  return request;
}

async function newPassword({
  email,
  token,
  password,
  password_confirmation,
}: {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}) {
  if (!email || !token || !password || !password_confirmation) {
    throw new Error(
      'email, token, password and password_confirmation are required',
    );
  }
  const request = await api.post<NewPasswordResponse>(
    `/customers/reset-password/${email}/${token}`,
    {
      password,
      password_confirmation,
    },
  );
  return request;
}

export function useLoginCustomer() {
  return useMutation(['login'], loginCustomer);
}

export function useForgotPassword() {
  return useMutation(['forgot-password'], forgotPassword);
}

export function useNewPassword() {
  return useMutation(['new-password'], newPassword);
}
