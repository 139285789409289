const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let browserName = 'unknown';
  let version = 'unknown';

  if (ua.indexOf('Firefox') > -1) {
    browserName = 'Firefox';
    version = ua.match(/Firefox\/([0-9.]+)/)?.[1] || '';
  } else if (ua.indexOf('Chrome') > -1) {
    browserName = 'Chrome';
    version = ua.match(/Chrome\/([0-9.]+)/)?.[1] || '';
  } else if (ua.indexOf('Safari') > -1) {
    browserName = 'Safari';
    version = ua.match(/Version\/([0-9.]+)/)?.[1] || '';
  } else if (ua.indexOf('Edge') > -1) {
    browserName = 'Edge';
    version = ua.match(/Edge\/([0-9.]+)/)?.[1] || '';
  }

  return [browserName, version, navigator.language].join('-');
};

export const generateDeviceToken = (): string => {
  const deviceInfo = [
    window.screen.width,
    window.screen.height,
    getBrowserInfo(),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  ].join('-');

  return btoa(deviceInfo);
};
