import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { FormCustomerCheckoutInfo } from '../../components/Forms/Checkout';
import ListCartProduct from '../../components/Lists/ListCartProduct';
import { useCartStore } from '../../stores/cart';
import ItemAddress from './DeliveryItems/ItemAddress';
import ItemDeliveryInstructions from './DeliveryItems/ItemDeliveryInstructions';
import Scheduling from './DeliveryItems/Scheduling';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../stores/user';
interface DeliveryProps {
  active: boolean;
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const Delivery: React.FC<DeliveryProps> = ({
  active,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const navigate = useNavigate();

  const { products, isExtension } = useCartStore((state) => ({
    products: state.products,
    isExtension: state.isExtension,
  }));

  const address = useUserStore((state) => state.address);

  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const handleOpenAddress = () => {
    navigate('/cart/address');
  };

  React.useEffect(() => {
    if (isExtension && scrollableRef.current) {
      scrollableRef.current.scrollTop = 0;
    }
  }, [scrollableRef]);

  const addressSelected = address != null;

  if (!active) return null;

  return (
    <div className="mt-[110px] overflow-y" id="cart overflow-y">
      <div ref={scrollableRef}></div>

      {isExtension || addressSelected ? (
        <>
          <ItemAddress />

          <Scheduling />

          <ItemDeliveryInstructions />
        </>
      ) : (
        <button
          type="button"
          onClick={handleOpenAddress}
          className="rounded-lg w-full border border-current text-primary flex flex-row items-center justify-between p-2"
        >
          <span className="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
            Calcular taxa e tempo de entrega
          </span>
          <ChevronRightIcon className="w-5 h-5" />
        </button>
      )}

      {isExtension && (
        <FormCustomerCheckoutInfo
          setExtensionCustomCouponCode={setExtensionCustomCouponCode}
          setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
        />
      )}

      {/* products */}
      <div
        style={
          isExtension
            ? { marginBottom: '20rem' }
            : {
                marginBottom:
                  Object.values(products).length >= 5 ? '4rem' : '8.5rem',
              }
        }
        className="flex flex-col items-start justify-start p-2 mt-2 h-full overflow-y"
      >
        {/* Lista de produtos */}
        <ListCartProduct />
      </div>
    </div>
  );
};

export default Delivery;
