import { Skeleton } from '@onbeef/components/skeleton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';
import { Club } from '../../queries/home/types';
import cx from 'classnames';
import {
  ClubSubscriptionStatus,
  Toast,
  formatCurrency,
} from '@onbeefapp/constants';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Stars from '../../../../../packages/assets/images/stars.png';
import WallPaper from '../../../../../packages/assets/images/wallpaper.png';
import Diamond from '../../assets/images/diamond.png';
import { useAuthStore } from '../../stores/login';
import { Helmet } from 'react-helmet';
import { ClubPaymentIntervalToHuman } from '.';

export const ClubPreview: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const merchant = useMerchantStore((state) => state.merchant);

  const navigate = useNavigate();

  const clubs = useMerchantStore((state) => state.clubs);

  const [clubName, setClubName] = React.useState('');
  const [clubID, setClubID] = React.useState<string>();
  const user = useAuthStore((state) => state.user);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const free = clubs?.find((c) => c.price_cents === 0);
  const paid = clubs?.find((c) => c.price_cents > 0);

  const handleSubscription = () => {
    if (!clubID) {
      Toast.error('Seleciona um clube para assinar');
      return;
    }

    const haveAccount = isAuthenticated && user?.taxpayer_id_number != null;
    const alreadyHaveSubscription =
      haveAccount &&
      !!user.clubs.find(
        (club) => club.status === ClubSubscriptionStatus.ACTIVE,
      );

    if (alreadyHaveSubscription) {
      Toast.error(
        'Você já possui uma assinatura ativa, para assinar esse clube primeiro cancele a atual.',
      );
    } else {
      const club = clubs?.find((c) => c.id === clubID);
      navigate(`/club/${clubID}`, {
        state: { is_free: club?.price_cents === 0 },
      });
    }
  };

  React.useEffect(() => {
    if (clubs && clubs.length === 1) {
      setClubName(clubs[0]?.name || '');
      setClubID(clubs[0]?.id);
    }
  }, [clubs]);

  if (!free && !paid) {
    navigate(-1);
    return;
  }

  return (
    <div className="w-full h-full">
      <Helmet>
        <meta name="title" content={`Clubes ${merchant?.name}`} />
        <meta
          name="description"
          content={clubs?.map((club) => club.name).join(', ')}
        />
      </Helmet>
      <div className="bg-primary z-[-9] absolute w-full h-60 opacity-50" />
      <img
        src={Stars}
        alt="Imagem do clube"
        className="w-full h-60 z-[-8] object-cover absolute top-0 grayscale"
      />
      <img
        src={WallPaper}
        alt="Imagem do clube"
        className="w-full h-60 z-[-10] object-cover absolute top-0 grayscale"
      />

      <div className="w-full h-full space-y-2">
        <HeaderBar className="p-4 !mb-16" colorButton="bg-white"></HeaderBar>
        <div className="flex flex-col space-y-4 items-center">
          <div className="absolute top-5 flex flex-col space-y-2">
            <img
              src={Diamond}
              alt="Imagem do clube"
              className="h-14 w-14 z-[-8] self-center"
            />
            <div className="text-lg font-medium text-contrastText">
              Clube de benefícios{' '}
              <span className="font-medium pt-1">
                {/* {merchantLoyaltyProgramme?.name} */}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Skeleton
        isLoading={isLoading}
        repeatQuantity={5}
        className="w-full h-10 rounded-lg"
      >
        <div className="flex flex-col w-full gap-6 p-2 rounded-t-2xl bg-white">
          <div className="flex flex-row gap-2 items-center">
            <img
              src={merchant?.logo}
              alt="Logotipo"
              className="w-10 h-10 rounded-full"
            />
            <span className="text-lg">Clubes {merchant?.name}</span>
          </div>
          <span className="text-lg font-medium">
            Participe e ganhe descontos ilimitados!
          </span>
          <div className="flex flex-col w-full gap-4">
            <ClubCard
              club={paid}
              value={clubName}
              setter={setClubName}
              setClubID={setClubID}
            />
            <ClubCard
              club={free}
              value={clubName}
              setter={setClubName}
              setClubID={setClubID}
            />

            <button
              type="button"
              onClick={() => handleSubscription()}
              className="bg-primary text-contrastText rounded-lg w-full p-2 mt-2"
            >
              Quero assinar
            </button>
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

interface ClubCardProps {
  club?: Club;
  value?: string;
  setter: (clubName: string) => void;
  setClubID: (clubID: string) => void;
}

const ClubCard: React.FC<ClubCardProps> = ({
  club,
  value,
  setter,
  setClubID,
}) => {
  const isSelected = club?.name === value;

  return (
    club && (
      <div className="w-full relative">
        {isSelected && (
          <CheckCircleIcon className="w-10 h-10 text-primary absolute right-[-10px] top-[-10px] transitiona-ll duration-300" />
        )}
        <button
          type="button"
          onClick={() => {
            setter(club.name);
            setClubID(club.id);
          }}
          className={cx(
            'w-full border-2 p-6 flex text-start flex-col gap-4 rounded-lg transition-all duration-300',
            isSelected ? 'border-current' : '',
          )}
        >
          <div className="w-full flex justify-between items-center">
            <span className="font-medium text-lg">{club.name}</span>
            <span className="font-medium">
              {club.price_cents === 0
                ? 'Grátis'
                : `${formatCurrency(club.price_cents / 100)} /${
                    ClubPaymentIntervalToHuman[club.interval]
                  }`}
            </span>
          </div>
          <ul className="flex flex-col gap-1">
            {club.messages?.map((message) => <li>{message}</li>)}
          </ul>
        </button>
      </div>
    )
  );
};
