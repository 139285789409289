import { Modal } from '@onbeef/components/modal';
import React from 'react';
import { RelatedProductsList } from '../../queries/related/types';
import { Product } from '@onbeefapp/constants';
import { useProductsStore } from '../../stores/products';
import CardProduct from '../Cards/CardProduct';

interface CartRelatedProductsModalProps {
  open: boolean;
  list?: RelatedProductsList;
  handleContinue: () => void;
  handleClose: () => void;
}

export const CartRelatedProductsModal: React.FC<
  CartRelatedProductsModalProps
> = ({ handleContinue, handleClose, open, list }) => {
  const handleRelatedListsProducts = useProductsStore(
    (state) => state.handleRelatedListsProducts,
  );

  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    if (!list) return;
    const p = handleRelatedListsProducts([list]);
    p && setProducts(p[list.id] || []);
  }, [list]);

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      className="h-[95%] md:h-[80%] w-full md:w-2/3"
    >
      <div className="flex flex-col h-full">
        <div className="w-full flex-none flex items-center justify-center p-4">
          <span className="text-lg font-medium">{list?.name}</span>
        </div>

        <div className="flex-1 overflow-hidden">
          <div
            className="h-full overflow-y-auto px-4"
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#eeeeee transparent',
            }}
          >
            <div className="flex flex-row flex-wrap items-center">
              {products.map((product, i) => (
                <div
                  key={`${product.id}-${i}`}
                  className="flex mb-6 w-1/2 md:w-1/3 lg:w-1/4 justify-center items-center pt-2"
                >
                  <CardProduct
                    key={`relatedProductCard${product.id}`}
                    product={product}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-none p-4">
          <button
            type="button"
            onClick={handleContinue}
            className="w-full rounded-lg px-4 py-2 bg-primary text-contrastText"
          >
            Continuar para o pagamento
          </button>
        </div>
      </div>
    </Modal>
  );
};
