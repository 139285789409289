import { ClubSubscriptionStatus } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { LoginResponse, UserClub } from '../queries/login/types';
import { getStorage } from '../utils/function';
import { useUserStore } from './user';

interface AuthState {
  isAuthenticated: boolean;
  user?: LoginResponse;
  setAuthState: (isAuthenticated: boolean, user: LoginResponse) => void;
  updateUser: (data: Partial<LoginResponse>) => void;
  updateUserClubSubscriptions: (clubs: UserClub[]) => void;
  updateLoyalty: (
    loyalty_programmes: LoginResponse['loyalty_programmes'],
  ) => void;
  setToken: (token: string, refresh: string) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      setAuthState: (isAuthenticated, user) => {
        get().setToken(user.access_token, user.refresh_token);
        if (user) {
          useUserStore.getState().set(user);
        }
        set({ isAuthenticated, user });
      },
      updateUser(data) {
        const user = get().user;
        if (user) {
          const newUser = { ...user, ...data };
          useUserStore.getState().set(newUser);
          set({ user: newUser });
        }
      },
      updateUserClubSubscriptions(clubs) {
        const user = get().user;
        if (user) {
          user.clubs = clubs.filter((club) => {
            // remove free club subscription when canceled
            if (
              club.status === ClubSubscriptionStatus.CANCELED &&
              club.expired_at === null
            ) {
              return false;
            }
            return true;
          });
          set({ user });
        }
      },
      updateLoyalty(loyalty_programmes) {
        const user = get().user;
        if (user) {
          user.loyalty_programmes = loyalty_programmes;
          set({ user });
        }
      },
      setToken: (token, refresh) => {
        localStorage.setItem('access_token', token);
        localStorage.setItem('refresh_token', refresh);
      },
      logout: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        useUserStore.getState().clear();
        set({
          isAuthenticated: false,
          user: undefined,
        });
      },
    }),
    {
      name: 'authStore',
      storage: createJSONStorage(() => getStorage()),
    },
  ),
);
